import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Layout } from "../components/layout"
import { DividerBlock } from "../components/dividerBlock"
import { EditionsList } from "../components/editionsList"
import ImageFadeIn from "react-image-fade-in"

const GertrudeEditions = ({ data }) => {
  const { gertrudeeditions } = data
  const sideBarText = "Editions"

  return (
    <Layout
      bgcolor={gertrudeeditions.backgroundColor}
      wrapperClass="gertrude-editions"
      accentColor={gertrudeeditions.accentColor}
    >
      <HelmetDatoCms seo={gertrudeeditions.seoMetaTags} />
      <section className="main-block">
        <div className="main-title">
          <h1 className="gothic--large large-title">
            {gertrudeeditions.title}
          </h1>
        </div>
        <div className="main-image">
          <ImageFadeIn
            src={gertrudeeditions.featuredImage.url}
            className="featured-title-image"
            alt={gertrudeeditions.featuredImage.alt}
          />
        </div>
        <div className="main-intro row">
          <div
            className="intro-text col sans--regular"
            dangerouslySetInnerHTML={{
              __html: gertrudeeditions.introduction,
            }}
          />
          <div className="intro-divider col">
            <DividerBlock accentColor={gertrudeeditions.accentColor} />
          </div>
        </div>
        <div className="main-text content">
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: gertrudeeditions.description,
            }}
          />
        </div>
      </section>
      <div className="editions-list container row">
        <div className="col-sm-2 col-xs-6 col">
          <h2 className="sidebar-text">{sideBarText}</h2>
        </div>
        <EditionsList />
      </div>
      <div className="editions-divider col-sm-5 col-xs-4 offset-xs-1 offset-sm-3 col">
        <DividerBlock accentColor={gertrudeeditions.accentColor} />
      </div>
    </Layout>
  )
}

GertrudeEditions.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
}

export default GertrudeEditions

export const query = graphql`
  query GertrudeEditionsQuery {
    gertrudeeditions: datoCmsGertrudeEdition {
      backgroundColor
      accentColor {
        hex
      }
      introduction
      featuredImage {
        title
				url(imgixParams: { h: "800", ar: "5:3", fit: "crop", crop: "focalpoint" })
        alt
      }
      description
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
    }
  }
`
